<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <!--  <el-select v-model="searchForm.userId" placeholder="请选用户" class="mgB10 custom-form-input">
        <el-option v-for="(item,index) in userList" :key="index" :label="item.userName" :value="item.id">
        </el-option>
      </el-select>
      <el-button type="primary" @click="search" :loading="loading" :disabled="loading" plain class="mgL10 mgB10">搜索
      </el-button> -->
      <el-form ref="form" :rules="formRules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="分配用户" class="custom-form-item">
              <el-select v-model="form.userId" placeholder="请选择用户" class="width-100" @change="userChange">
                <el-option v-for="(item,index) in userList" :key="index" :label="item.userName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="分配粮库" class="custom-form-item">
              <el-select v-model="form.liangId" multiple placeholder="请选择" class="width-100" @remove-tag="removeTag"
                @change="liangChange">
                <el-option v-for="item in liangList" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          userId: "",
          liangId: []
        },
        userList: [],
        liangList: [],
        formRules: {}
      }
    },
    mounted() {
      this.getUserName()
    },
    methods: {
      //移除
      removeTag(e) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios.Post(this.$api.grainRemove, {
          "userId": this.form.userId,
          "liangIds[]": [e].toString()
        }).then(() => {
          setTimeout(() => {
            loading.close();
          }, 500)
        }).catch((err) => {
          setTimeout(() => {
            loading.close();
          }, 500)
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      },
      liangChange(e) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios.Post(this.$api.grainAdd, {
          "userId": this.form.userId,
          "liangIds[]": e.toString()
        }).then(() => {
          setTimeout(() => {
            loading.close();
          }, 500)
        }).catch((err) => {
          setTimeout(() => {
            loading.close();
          }, 500)
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      },
      //用户选择更爱
      userChange(e) {
        this.grainLiangsNot(e);
      },
      //获取用户名称
      getUserName() {
        this.$axios.Get(this.$api.userName, {}).then(res => {
          this.userList = res.data
        })
      },
      grainLiangsNot(e) {
        this.$axios.Get(this.$api.grainLiangsNot, {
          userId: e
        }).then(res => {
          this.liangList = res.data;
          this.grainLiangs(e);
        })
      },
      grainLiangs(e) {
        this.$axios.Get(this.$api.grainLiangs, {
          userId: e
        }).then(res => {
          let ids = [];
          res.data.forEach(item => {
            ids.push(item.id)
          })
          this.form.liangId = ids;
          this.liangList = [...this.liangList, ...res.data]
        })
      },
    }
  }
</script>

<style scoped lang="less">
</style>
